import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Content from 'components/new/content'
import H2 from 'components/new/typography/h2'
import H3 from 'components/new/typography/h3'
import P from 'components/new/typography/p'
import Button from 'components/button/button'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const PrecisionUpgradesPage = props => {
  const {
    data: { heroImage, planterPUKImage, sprayerPUKImage, harvestEquipmentPUKImage },
  } = props
  return (
    <Layout>
      <Helmet>
        <title>Precision Upgrades | Hutson Inc</title>
      </Helmet>

      <Hero title='Precision Upgrades' image={getImage(heroImage)} overlayOpacity={0.25} />

      <Content kind='full'>
        <H2>Unlock more precision from existing machines</H2>
        <P>
          Get more out of your current equipment with a Precision Upgrade Kit. These upgrades are
          designed to enhance the performance of your existing equipment, unlocking its full
          potential and maximizing your return on investment. Whether you're looking to improve seed
          placement with planter upgrades, add precision and productivity to your sprayers, or
          reduce grain loss with harvest upgrades, John Deere offers tailored solutions to fit your
          needs. Installed by the skilled technicians at Hutson, these upgrades offer a
          cost-effective solution to modernize your fleet, ensuring your equipment runs like new.
          Experience the difference in your operation's efficiency and yield with John Deere
          Precision Upgrade Kits from Hutson, Inc.
        </P>
        <div>
          <Button
            as={ButtonOutboundLink}
            color='green'
            ghost
            href='https://www.deere.com/en/technology-products/precision-ag-technology/precision-upgrades/'
          >
            Learn more at Deere.com
          </Button>
        </div>
      </Content>
      <Grid
        reverse
        image={planterPUKImage}
        heading='Planter Upgrades'
        description='Enhance planting accuracy, improve seed placement and emergence, and boost crop yields with planter precision upgrades.'
        primaryButtonLink='https://www.deere.com/en/technology-products/precision-ag-technology/precision-upgrades/planter-upgrades/'
        primaryButtonText='Learn more at Deere.com'
        secondaryButtonLink='https://www.deere.com/en/technology-products/precision-ag-technology/precision-upgrades/planter-upgrades/planter-upgrades-form/'
        secondaryButtonText='Find available upgrades for your planter'
      />
      <Grid
        image={sprayerPUKImage}
        heading='Sprayer Upgrades'
        description='Add value to your existing sprayer with upgrades like ExactApply™, which minimizes over-application and crop burn, and Individual Nozzle Control Pro, which provides precise application and reduces chemical waste.'
        primaryButtonLink='https://www.deere.com/en/technology-products/precision-ag-technology/precision-upgrades/sprayer-upgrades/'
        primaryButtonText='Learn more at Deere.com'
      />
      <Grid
        reverse
        image={harvestEquipmentPUKImage}
        heading='Harvest Equipment Upgrades'
        description='Reduce grain loss at the header, maintain consistent harvest speed, and increase visibility with precision upgrades for harvesting equipment.'
        primaryButtonLink='https://www.deere.com/en/technology-products/precision-ag-technology/precision-upgrades/harvest-upgrades/'
        primaryButtonText='Learn more at Deere.com'
      />
    </Layout>
  )
}

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 600px) {
    height: 350px;
  }

  @media (min-width: 900px) {
    height: 450px;
  }
`

const Grid = props => {
  const {
    reverse,
    image,
    heading,
    description,
    primaryButtonLink,
    primaryButtonText,
    secondaryButtonLink,
    secondaryButtonText,
  } = props
  return (
    <FlexGrid reverse={reverse}>
      <div>
        <GatsbyImage
          image={getImage(image)}
          objectFit='cover'
          objectPosition='50% 50%'
          style={{ height: '100%', width: '100%' }}
          alt=''
        />
      </div>
      <FlexGridTextColumn>
        <H3 as='h2'>{heading}</H3>
        <P>{description}</P>
        <div>
          <Button as={ButtonOutboundLink} color='green' ghost href={primaryButtonLink}>
            {primaryButtonText}
          </Button>
          {secondaryButtonLink ? (
            <SecondaryOutboundLink href={secondaryButtonLink}>
              {secondaryButtonText}
            </SecondaryOutboundLink>
          ) : null}
        </div>
      </FlexGridTextColumn>
    </FlexGrid>
  )
}

const FlexGrid = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;

  @media (min-width: 900px) {
    flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};

    > * {
      flex-grow: 0;
      flex-shrink: 0;
      width: 50%;
    }
  }
`

const FlexGridTextColumn = styled.div`
  background-color: ${props => props.theme.color.n20};
  padding: 36px 24px;

  @media (min-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 64px;
  }
`

const ButtonOutboundLink = styled(OutboundLink)`
  display: inline-block;
  margin: 8px 0;
  text-decoration: none;
`

const SecondaryOutboundLink = styled(OutboundLink)`
  color: ${props => props.theme.color.g400};
  display: block;
  margin: 16px 0 0;
  text-decoration: underline;

  :hover,
  :focus,
  :active {
    text-decoration: none;
  }
`

export const pageQuery = graphql`
  query precisionUpgradesPageQuery {
    heroImage: file(relativePath: { eq: "precision-upgrades/precision-upgrades-hero.jpg" }) {
      ...FullWidthImage
    }
    agInspectionsImage: file(relativePath: { eq: "landing-pages/inspections/ag-inspections.jpg" }) {
      ...SharpImage900
    }
    planterPUKImage: file(
      relativePath: { eq: "precision-upgrades/planter-precision-upgrade-kit.jpg" }
    ) {
      ...SharpImage900
    }
    sprayerPUKImage: file(relativePath: { eq: "precision-upgrades/exact-apply.jpg" }) {
      ...SharpImage900
    }
    harvestEquipmentPUKImage: file(
      relativePath: {
        eq: "precision-upgrades/harvest-equipment-precision-upgrades-r4d092124_rrd.jpg"
      }
    ) {
      ...SharpImage900
    }
  }
`

export default PrecisionUpgradesPage
